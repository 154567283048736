// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/resignation-banner.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/checklist.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../images/close-one.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../images/log.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resignation-banner{\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n}\r\n.resignation-container h3{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.resignation-container h4{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.exitlist h4{\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\r\n}\r\n.exitlist label{\r\n  font-family: 'Avenir-Black';\r\n}\r\n\r\n.enableexit h4{\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\r\n}\r\n.exitpolicies{\r\n  font-family: 'Avenir-Black';\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;\r\n}", "",{"version":3,"sources":["webpack://./src/shared/styles/resignation.css"],"names":[],"mappings":"AAAA;EACE,6DAA8D;AAChE;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,6DAAqD;AACvD;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,6DAAqD;AACvD;AACA;EACE,2BAA2B;EAC3B,6DAA+C;AACjD","sourcesContent":[".resignation-banner{\r\n  background: url(../../images/resignation-banner.jpg) no-repeat;\r\n}\r\n.resignation-container h3{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.resignation-container h4{\r\n  font-family: 'Avenir-Black';\r\n}\r\n.exitlist h4{\r\n  background: url(../../images/checklist.svg) no-repeat;\r\n}\r\n.exitlist label{\r\n  font-family: 'Avenir-Black';\r\n}\r\n\r\n.enableexit h4{\r\n  background: url(../../images/close-one.svg) no-repeat;\r\n}\r\n.exitpolicies{\r\n  font-family: 'Avenir-Black';\r\n  background: url(../../images/log.svg) no-repeat;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
